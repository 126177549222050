import {generateStyleClasses, styleHelper, formatPrice} from '../helper'
import {useNavigate} from 'react-router-dom'
import {
  getAddPostIntegrationUrl,
  getCookies,
  getNoImageLogo,
  redirectionInterceptor,
  setBackURLForAddPost,
} from '../../../../helpers/commonFunctions'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import {ReactTooltipContainer} from '../../../common/ReactTooltipContainer'
import CustomDropdown from './MoreActionsDropdown'
import {ListingInterfaceDesktop, POST_ACTIONS, PostActionInput} from './lisitingtypes'
import {useChatFunctions} from 'opensooq-chat'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {
  logMyListingsQualityListingClick,
  logMyListingsStatsListingClick,
  logMyListingsViewListingClick,
} from '../../../../../logging/helpers/commonLoggingFunctions'
import {EnumUxTypes} from '../../../../../logging/types/LoggingTypes'

type NewMyListingsDesktopProps = {
  data: ListingInterfaceDesktop
  handlePostAction: ({action, listing, isActive}: PostActionInput) => void
}

export default function NewMyListingsDesktop({data, handlePostAction}: NewMyListingsDesktopProps) {
  const {getUnreadPostRooms} = useChatFunctions()
  const [unReadChatsCount, setUnReadChatsCount] = useState<number>(0)
  const navigate = useNavigate()
  const lang = useLang()
  const Intl = useIntl()
  const ImageMediaIcons = () => {
    return (
      <div className='d-flex gap-2 ms-1 listing-media-icons-container'>
        {data.listingImageCount > 0 && (
          <span className='listing-media-icons'>{data.listingImageCount}</span>
        )}
        {data.mediaIcons?.map((mediaType, index) => {
          return (
            <span className='listing-media-icons' key={index}>
              <img src={mediaType} width={12} height={12} alt='' />
            </span>
          )
        })}
      </div>
    )
  }

  const getUnreadChat = (listingId: number) => {
    getUnreadPostRooms(listingId)
      .then((count: number) => {
        setUnReadChatsCount(count)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  useEffect(() => {
    getUnreadChat(data?.listingId)
  }, [])

  const redirectToStats = () => {
    logMyListingsStatsListingClick(data.loggingData, 'wallet_cell', EnumUxTypes.BUTTON)
    setTimeout(() => {
      navigate(`/listings/my-listings/listing-stats?id=${data.listingId}`)
    }, 0)
  }

  const handleImage = (imgUri) => {
    if (!imgUri) return getNoImageLogo(lang)
    return process.env.REACT_APP_ARBOK_URL + '200x0/' + imgUri + '.jpg' || ''
  }

  const redirectToEditPost = (type: string) => {
    if (!data.quality.isEnabled) return
    setBackURLForAddPost()
    logMyListingsQualityListingClick(data.loggingData, 'listing_cell', EnumUxTypes.LINK)
    window.location.href = window.location.href = getAddPostIntegrationUrl(
      getCookies().NEXT_LOCALE,
      type,
      data.listingId ?? 0,
      0,
      false
    )
  }

  const textSplitter = (text: string) => {
    return text.split('\n').map((str, index) => {
      return (
        <p className='m-0 mb-1' key={index}>
          {str ?? ''}
        </p>
      )
    })
  }

  const redirectToPost = (e: any, url: string) => {
    if (!e.target.classList.contains('action')) {
      logMyListingsViewListingClick(data.loggingData, 'listing_cell', EnumUxTypes.LIST_CELL)
      window.open(url, '_blank')
    }
  }
  const onClickListingHandler = (e: any) => {
    redirectToPost(e, `/listings/my-listings/listing-view?id=${data.listingId}`)
  }

  const handlePostActionHandler = (action: string, isActive: boolean) => {
    handlePostAction({action: action, isActive: isActive, listing: data})
  }
  const cpsData = data?.strs?.split(/,\s*/) // Split by commas, allowing for optional spaces
  const adjustedCpsData = cpsData
    ?.reduce((acc, item) => {
      const trimmedItem = item.trim()

      // Replace '1' with 'Delivery Option'
      if (trimmedItem === '1') {
        acc.push(Intl.formatMessage({id: 'adjustedCpsMessageDeliveryOption'}))
      } else if (trimmedItem) {
        acc.push(trimmedItem) // Only add non-empty trimmed items
      }

      return acc
    }, [])
    .filter((item) => item !== '') // Filter out any empty strings
    .join(lang === 'ar' ? ' ،\u200F ' : ', ')

  return (
    <div className='d-flex flex-column gap-2 my-12'>
      <div className='row'>
        <div
          id='listing-info'
          data-id={`my-listing-info-${data.listingId}`}
          className='col col-2-8 d-flex gap-2 hoverPointer'
          onClick={(e) => onClickListingHandler(e)}
        >
          <div>
            <img
              src={handleImage(data.listingImage)}
              width={80}
              height={80}
              className='rounded-3 customImg'
              alt=''
            />
            {ImageMediaIcons()}
          </div>
          <div className='d-flex flex-column gap-1 w-75'>
            <span
              id={`myListingSnippet-${data.listingId}`}
              style={styleHelper({
                color: data.title?.textColor,
              })}
              className={`${generateStyleClasses(data.title?.style)}  text-truncate w-75`}
            >
              {data.title.text ?? ''}
            </span>
            <span className='customPostTitleText' style={{height: '40px', lineHeight: '1.6'}}>
              {adjustedCpsData}
            </span>
            <span
              style={styleHelper({
                color: data.price?.textColor,
              })}
              className={`${generateStyleClasses(data.price?.style)}`}
            >
              {data.price?.text ? formatPrice(data.price.text) : ''}
            </span>
          </div>
        </div>
        <div
          id='listing-category'
          data-id={`my-listing-category-${data.listingId}`}
          className='d-flex flex-column col col-1'
        >
          <span
            className={`${generateStyleClasses(data.subCategory?.style)}`}
            style={styleHelper({
              color: data.subCategory?.textColor,
            })}
          >
            {data.subCategory?.text ?? ''}
          </span>
          <span
            className={`${generateStyleClasses(data.category?.style)} mb-1`}
            style={styleHelper({
              color: data.category?.textColor,
            })}
          >
            {data.category?.text ?? ''}
          </span>
        </div>
        <div
          id='listing-location'
          data-id={`my-listing-location-${data.listingId}`}
          className='col col-1'
        >
          <span
            className={`${generateStyleClasses(data.location?.style)}`}
            style={styleHelper({
              color: data.location?.textColor,
            })}
          >
            {textSplitter(data.location?.text) ?? '-'}
          </span>
        </div>
        <div
          id='listing-created'
          data-id={`my-listing-created-${data.listingId}`}
          className='col col-1'
        >
          <span
            className={`${generateStyleClasses(data.created?.style)}`}
            style={styleHelper({
              color: data.created?.textColor,
            })}
          >
            {textSplitter(data.created?.text) ?? '-'}
          </span>
        </div>
        <div
          id='listing-expires'
          data-id={`my-listing-expires-${data.listingId}`}
          className='col col-1'
        >
          <span
            className={`${generateStyleClasses(data.expires?.style)}`}
            style={styleHelper({
              color: data.expires?.textColor,
            })}
          >
            {textSplitter(data.expires?.text) ?? '-'}
          </span>
        </div>
        <div id='listing-quality' className='col col-0-7'>
          <span
            className={`${generateStyleClasses(data.quality?.style)} ${
              data.quality?.isEnabled ? 'hoverPointer' : 'hoverNotAllowed'
            }`}
            style={styleHelper({
              color: data.quality?.textColor,
            })}
            data-id={`my-listing-quality-${data.listingId}`}
            onClick={() => redirectToEditPost('edit')}
          >
            {data.quality?.text ?? '-'}
          </span>{' '}
        </div>

        <div className='row col col-1-6'>
          <div id='listing-views' className='col col-6'>
            <span
              className={`${generateStyleClasses(data.leads?.totalViews?.style)} hoverPointer`}
              data-id={`my-listing-views-${data.listingId}`}
              style={styleHelper({
                color: data.leads?.totalViews?.textColor,
              })}
              onClick={redirectToStats}
            >
              {data.leads?.totalViews?.text ?? '-'}
            </span>
          </div>
          <div id='listing-leads' className='col col-6'>
            <span
              className={`${generateStyleClasses(data.leads?.totalLeads?.style)} hoverPointer`}
              style={styleHelper({
                color: data.leads?.totalLeads?.textColor,
              })}
              data-id={`my-listing-leads-${data.listingId}`}
              onClick={redirectToStats}
            >
              {data.leads?.totalLeads?.text ?? '-'}
            </span>
          </div>
        </div>
        <div id='listing-leads-actions' className='col col-1-1 d-flex flex-column gap-5'>
          {data.leadsActions.map((action) => {
            return (
              <span
                style={styleHelper({
                  color: action?.textColor,
                })}
                key={action?.text}
                className={`d-flex gap-2 ${action?.isActive ? 'hoverPointer' : 'hoverNotAllowed'}`}
                data-id={`my-listing-leads-actions-${data.listingId}-${action?.actionType}`}
                onClick={() =>
                  handlePostAction({
                    action: action?.actionType,
                    isActive: action?.isActive,
                    listing: data,
                  })
                }
              >
                <img
                  src={action?.icon}
                  width={18}
                  height={18}
                  alt=''
                  style={{filter: !action?.isActive ? 'grayscale(1)' : ''}}
                />
                <span className={`${generateStyleClasses(action?.style)}`}>
                  {action.text ?? ''}
                </span>
              </span>
            )
          })}
        </div>
        <div id='listing-actions' className='col col-1-25 d-flex flex-column gap-2 ms-5'>
          {data.postActions.map((action) => {
            if (action.actionType === POST_ACTIONS.MORE) {
              return (
                <CustomDropdown
                  key={data.listingId}
                  handlePostAction={handlePostActionHandler}
                  action={action}
                  listingId={data.listingId}
                />
              )
            } else {
              return (
                <span
                  key={action.text}
                  style={styleHelper({color: action.textColor})}
                  className={`d-flex gap-2 hoverPointer `}
                  data-id={`my-listing-actions-${data.listingId}-${action.actionType}`}
                  onClick={() => handlePostAction({action: action.actionType, listing: data})}
                >
                  <span
                    className={`${
                      action.actionType === POST_ACTIONS.CHAT ? 'listing-chat_container ' : ''
                    }`}
                  >
                    <img src={action.icon} width={18} height={18} alt='' />
                    {action.actionType === POST_ACTIONS.CHAT && (
                      <span className='listing-chat_counter_desktop fw-bold'>
                        {unReadChatsCount <= 99 ? unReadChatsCount : '+99'}
                      </span>
                    )}
                  </span>
                  <span className={generateStyleClasses(action.style)}>{action.text ?? ''}</span>
                </span>
              )
            }
          })}
        </div>
      </div>
      <div className='d-flex gap-7 align-items-center py-2'>
        <span data-id={`my-listing-id-${data.listingId}`}>
          {Intl.formatMessage({id: 'mylistings.container.listingId'})}: {data?.listingId ?? ''}
        </span>
        <span
          style={styleHelper({
            color: data.listingStatus?.textColor,
            backgroundColor: data.listingStatus?.backgroundColor,
          })}
          data-id={`my-listing-status-${data.listingId}`}
          className={`${generateStyleClasses(data.listingStatus?.style)} px-3 pt-1 rounded-1`}
        >
          {data.listingStatus?.text ?? ''}
        </span>
        {data.applicantsStatus && (
          <span
            style={styleHelper({
              color: data.applicantsStatus?.textColor,
              backgroundColor: data.applicantsStatus?.backgroundColor,
            })}
            data-id={`my-listing-status-${data.listingId}`}
            className={`hoverPointer px-3 pt-1 rounded-1`}
            onClick={() => handlePostActionHandler(data.applicantsStatus?.actionType, true)}
          >
            <span className={`${generateStyleClasses(data.applicantsStatus?.style)}`}>
              {data.applicantsStatus?.text ?? ''}
            </span>
          </span>
        )}
        <div className='d-flex gap-2'>
          {!data.isRejected &&
            data.flags.map((flag, idx) => {
              return (
                <span
                  key={flag?.text}
                  data-id={`my-listing-flags-${data.listingId}-${flag?.text}`}
                  style={{backgroundColor: flag?.backgroundColor}}
                  className={`${flag.backgroundColor ? 'px-3 pt-1 rounded-1' : ''}`}
                >
                  {idx != 0 && <span> | </span>}
                  {flag.icon && (
                    <img src={flag.icon} width={15} height={15} alt='' className='mx-2' />
                  )}
                  <span
                    style={styleHelper({
                      color: flag.textColor,
                    })}
                    className={`${generateStyleClasses(flag.style)} `}
                  >
                    {flag.text ?? ''}
                  </span>
                </span>
              )
            })}
          {data.isRejected && data.rejectionReason && (
            // <span>
            //   <span>{Intl.formatMessage({id: 'mylistings.list.rejectionReason.1'})}</span>
            //   <span
            //     className='fw-bold text-decoration-underline hoverPointer'
            //     onClick={() => handlePostActionHandler(POST_ACTIONS.TERMS_OF_USE, true)}
            //   >
            //     {Intl.formatMessage({id: 'mylistings.list.rejectionReason.2'})}
            //   </span>
            //   <span>{Intl.formatMessage({id: 'mylistings.list.rejectionReason.3'})}</span>
            // </span>
            <span
              style={styleHelper({
                color: data.rejectionReason?.textColor,
                backgroundColor: data.rejectionReason?.backgroundColor,
              })}
              data-id={`my-listing-status-${data.listingId}`}
              className={`hoverPointer px-3 pt-1 rounded-1`}
            >
              <img
                src={data.rejectionReason.icon}
                width={18}
                height={18}
                alt=''
                className='mb-1 me-1'
              />
              <span className={`${generateStyleClasses(data.rejectionReason?.style)}`}>
                {data.rejectionReason?.text ?? ''}
              </span>
            </span>
          )}
        </div>
      </div>
      <ReactTooltipContainer myListingSnippet={data.title?.text} myListingId={data?.listingId} />
    </div>
  )
}
