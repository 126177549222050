import {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import getAccountStatsApi from '../../../api/account/getAccountStats.api';
import accountFeaturesApi from '../../../api/opensooq/accountFeatures.api';
import sellerApi from '../../../api/opensooq/seller.api';
import buyerApi from '../../../api/opensooq/buyer.api.';
import accountLimitsApi from '../../../api/opensooq/accountLimits.api';
import {deleteCookieKey, getCookies, getProfileFunction, isDeviceMobile} from '../../helpers/commonFunctions';
import { logScreenLoad } from '../../../logging/helpers/commonLoggingFunctions';
import { REGULAR_MEMBER_TYPE } from '../../helper/MemberTypes';
import { PointOfSales } from '../../helper/PointOfSales';
import { BuyerInterface } from '../../interfaces/BuyerInterface';
import { MemberInfoInterface } from '../../interfaces/MemberInfoInterface';
import { MyAccountFeatures } from '../../interfaces/MyAccountFeatures';
import { MyAccountStatesInterface } from '../../interfaces/MyAccountStatesInterface';
import { SellerInterface } from '../../interfaces/SellerInterface';
import { ShopLimitInterface } from '../../interfaces/ShopLimitInterface';
import AddNewListing from '../common/AddNewListing';
import { MemberShipModal } from '../payment/MemberShipModal';
import { ProfileHeader } from '../common/ProfileHeader';
import AccountFeatures from './AccountFeatures';
import AccountStats from './AccountStats';
import Buyer from './Buyer';
import Products from './Products';
import Seller from './Seller';
import {useIntl} from "react-intl";
import {useLang} from "../../i18n/OpenSooqi18n";
import {showSuccess} from "../../helper/SystemAlert";
import CompleteProfileModalMobile from "../accounts/profile/CompleteProfileModal/CompleteProfileModalMobile";
import CompleteProfileModalDesktop from "../accounts/profile/CompleteProfileModal/CompleteProfileModalDesktop";
import {AuthContext} from "../../i18n/AuthProvider";

export function MyOpenSooq() {
    const [accountState,setAccountState] = useState <MyAccountStatesInterface>(); 
    const [accountFeature,setAccountFeature] = useState <MyAccountFeatures>(); 
    const [shopLimit, setShopLimit] = useState<ShopLimitInterface>();
    const [profile, setProfile] = useState<MemberInfoInterface>();
    const [showBundle, setShowBundle] = useState<boolean>(false);
    const [memberId, setMemberId] = useState<number>(0);
    const [seller,setSeller] = useState <SellerInterface>(); 
    const [buyer,setBuyer] = useState <BuyerInterface>(); 
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const action = params.get('action') ?? '';
    const navigate = useNavigate();
    const Intl = useIntl();
    const lang = useLang();

    const {userIdentity} = useContext(AuthContext);
    const checkIfOpenCompletePopUp =
        !userIdentity?.complete_profile?.date_of_birth_filled ||
        !userIdentity?.complete_profile?.email_filled ||
        !userIdentity?.complete_profile?.gender_filled
    const [showCompleteProfileModal, setShowCompleteProfileModal] = useState<boolean>(false);
    const getUpdatedUserProfileCookie = getCookies().saveUserProfileData;
    const getOpenCompleteUserProfileCookie = getCookies().clickedOnMyListings;

    useEffect(() => {
        if (getUpdatedUserProfileCookie) {
            showSuccess(Intl, lang);
            deleteCookieKey('saveUserProfileData');
        }

        if (getOpenCompleteUserProfileCookie && checkIfOpenCompletePopUp) {
            setShowCompleteProfileModal(true);
            deleteCookieKey('clickedOnMyListings');
        }
    }, []);

    function navigateTo(url : string) {
        navigate(url);
    }
        
    useEffect(() => {
        getProfileFunction().then(response => {
            const results = response.data;
            setProfile(results);
        })
        sellerApi().then((response) => {
            setSeller(response.data);
        })
        accountFeaturesApi().then((response) => {
            setAccountFeature(response.data);
        })
        getAccountStatsApi().then((response) => {
            setAccountState(response.data)
        })
        buyerApi().then((response) => {
            setBuyer(response.data)
        })
        accountLimitsApi().then((response) => {
            setShopLimit(response.data);
        })
        if(action && action === 'upgradeMembership' && getCookies().userInfo && getCookies().userInfo.id){
            setMemberId(getCookies().userInfo.id)
            setShowBundle(true);
        }
    },[])

    useEffect(()=>{
        logScreenLoad()
    }, [])
    
    return (
        <>
            <ProfileHeader profile={profile}/>
            <div className="row g-5 g-xl-8 mt-4">
                <div className="col-xl-4 mt-3">
                    <AccountFeatures accountFeature={accountFeature} navigateTo={navigateTo}/>
                </div>
                <div className="col-xl-4 mt-3">
                    <Seller seller={seller} navigateTo={navigateTo}/>
                </div>
                <div className="col-xl-4 mt-3">
                    <Buyer buyer={buyer} navigateTo={navigateTo}/>
                </div>
                <div className="col-xl-4 mt-3">
                    <AccountStats accountState={accountState} navigateTo={navigateTo}/>
                </div>
                <div className="col-xl-4 mt-3">
                    <Products/>
                </div>
                {profile && profile.member_basic.member_type == REGULAR_MEMBER_TYPE && accountFeature && shopLimit && <div className="col-xl-4 mt-3">
                    <AddNewListing source={'shop'} pos={PointOfSales.UPGRADE_MEMBERSHIP_BOTTOM_WIDGET} forceMobile={true} widgetName={'upgrade_account_card'}/>
                </div>}
            </div>
            <MemberShipModal show={showBundle} isRedirectd={true} backUrl={window.location.origin} type={['MemberShip']} handleClose={() => {setShowBundle(false)}} id={memberId} pos={PointOfSales.UPGRADE_MEMBERSHIP_PROFILE_HEADER_WIDGET}/>
            {isDeviceMobile() ?
            <CompleteProfileModalMobile show={showCompleteProfileModal} setShowModal ={setShowCompleteProfileModal} /> :
            <CompleteProfileModalDesktop show={showCompleteProfileModal} setShowModal={setShowCompleteProfileModal} /> }
        </>
    )
}
