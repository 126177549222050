import MyListingsCredit from '../new-my-listings/MyListingsCredit'
import MyListingsLive from '../new-my-listings/MyListingsLive'
import ListingsTable from '../new-my-listings/ListingsTable'
import ListingFilters from '../new-my-listings/ListingFilters'
import MyListingsContainer from './new-my-listings/MyListingsContainer'
import {useEffect, useState} from 'react'
import newListingsApi from '../../../../api/newMyListings/newListings.api'
import {showError} from '../../../helper/SystemAlert'
import {useLang} from '../../../i18n/OpenSooqi18n'
import mylisingsSearchFieldsApi from '../../../../api/newMyListings/mylisingsSearchFields.api'
import {SearchFields} from '../../../interfaces/MyListings'
import {isDeviceMobile} from '../../../helpers/commonFunctions'
import {FilterFieldMobileI} from '../../../interfaces/MyListingsMobile'
import LearnMoreModal from '../new-my-listings/learnMorePopup/LearnMoreModal'
import useFiltersStore from './FilterStore'
import {useIntl} from 'react-intl'
import mylisingLiveListingsApi from '../../../../api/newMyListings/mylisingLiveListings.api'
import mylisingPillsCountApi from '../../../../api/newMyListings/mylisingPillsCount.api'
import { logScreenLoad } from '../../../../logging/helpers/commonLoggingFunctions'

export function MyListings() {
  const lang = useLang()
  const intl = useIntl()
  const [openLearnMore, setOpenLearnMore] = useState(false)
  const [LearnMoreLink, setLearnMoreLink] = useState('')
  const [searchUrl, setSearchUrl] = useState<string>()
  const [isListingLoading, setIsListingLoading] = useState<boolean>(true)
  const [isPillsCountsLoading, setIsPillsCountsLoading] = useState<boolean>(true)
  const [listings, setListings] = useState<any[]>([])
  const [statusCount, setStatusCount] = useState<any>({})
  const [pagesCount, setPagesCount] = useState<number>(0)
  const [selectedPage, setSelectedPage] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingLanding, setIsLoadingLanding] = useState<boolean>(true)
  const [landingDataFilters, setLandingDataFilters] = useState<
    SearchFields | FilterFieldMobileI | null
  >(null)
  const [liveListingData, setLiveListingData] = useState<any>({})

  const {setGlobalFiltersDisable} = useFiltersStore()
  const handlePageChange = (page) => {
    setCurrentPage(page)
    setSelectedPage(page)
  }

  const handleCloseLearnMore = () => {
    setLearnMoreLink('')
    setOpenLearnMore(false)
  }

  useEffect(() => {
    if (
      (searchUrl && searchUrl.includes('sort') && searchUrl.includes('limit')) ||
      isDeviceMobile()
    ) {
      fetchListings()
      fetchPillsCountsData()
    }
  }, [searchUrl, selectedPage])

  const fetchListings = async () => {
    try {
      let searchParam = searchUrl || ''
      if (selectedPage) searchParam += `&page=${selectedPage}`
      setIsListingLoading(true)
      newListingsApi(searchParam)
        .then((response) => {
          setListings(response.data?.listings || [])
          setPagesCount(response.data?.pagesNumber || 0)
        })
        .catch(() => {
          setPagesCount(0)
          setListings([])
        })
        .finally(() => {
          setIsListingLoading(false)
        })
    } catch (error) {
      setIsListingLoading(false)
      setListings([])
      showError(intl, lang, {message: intl.formatMessage({id: 'error'})})
      // showError(Intl, lang)
    }
  }

  const fetchLandingData = () => {
    mylisingsSearchFieldsApi()
      .then((response) => {
        setLandingDataFilters(response.data)
      })
      .catch(() => {
        showError(intl, lang, {message: intl.formatMessage({id: 'error'})})
      })
  }

  const fetchLiveListingsData = () => {
    mylisingLiveListingsApi()
      .then((response) => {
        setLiveListingData(response.data)
        setIsLoadingLanding(false)
        if (response.data?.totalListings > 0) {
          setGlobalFiltersDisable(false)
        }
      })
      .catch(() => {
        showError(intl, lang, {message: intl.formatMessage({id: 'error'})})
      })
  }


  const fetchPillsCountsData = async () => {
    try {
      let searchParam = searchUrl || ''
      if (selectedPage) searchParam += `&page=${selectedPage}`
      setIsPillsCountsLoading(true)
      mylisingPillsCountApi(searchParam)
        .then((response) => {
          setStatusCount(response.data || {})
        })
        .catch(() => {
          setStatusCount({})
        })
        .finally(() => {
          setIsPillsCountsLoading(false)
        })
    } catch (error) {
      setIsPillsCountsLoading(false)
      setStatusCount({})
      showError(intl, lang, {message: intl.formatMessage({id: 'error'})})
    }
  }

  useEffect(() => {
    fetchLandingData()
    fetchLiveListingsData()
    logScreenLoad()
  }, [])

  const urlSearchSetter = (url: string) => {
    setSearchUrl(url)
  }

  const handleDeleteLocalListing = (id: number) => {
    setListings((prev) => prev.filter((listing) => listing.listingId != id))
  }

  return (
    <>
      <div className='d-flex custom-listing-cards-holder gap-3 justify-content-between mx-2'>
        {!isDeviceMobile() && (
          <MyListingsLive
            setLearnMoreLink={setLearnMoreLink}
            setOpenLearnMore={setOpenLearnMore}
            isLoadingLanding={isLoadingLanding}
            liveListingData={liveListingData}
          />
        )}
        <MyListingsCredit setLearnMoreLink={setLearnMoreLink} setOpenLearnMore={setOpenLearnMore} />
      </div>
      {
        <div className='my-10 mx-2'>
          <ListingFilters
            statusCount={statusCount}
            urlSearchSetter={urlSearchSetter}
            handlePageChange={handlePageChange}
            landingDataFilters={landingDataFilters}
          />
        </div>
      }
      <div className='mt-10 mx-2'>
        <MyListingsContainer
          isListingLoading={isListingLoading}
          listings={listings}
          pagesCount={pagesCount}
          setSelectedPage={setSelectedPage}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          handleDeleteLocalListing={handleDeleteLocalListing}
        />
      </div>

      {openLearnMore && LearnMoreLink && (
        <LearnMoreModal
          open={openLearnMore}
          handleCloseLearnMore={handleCloseLearnMore}
          link={LearnMoreLink}
        />
      )}
    </>
  )
}
