export const POST_ACTIONS = {
  ACTIVE: 'active',
  EDIT: 'edit',
  COPY: 'copy',
  DELETE: 'delete',
  DELETE_FOREVER: 'delete_forever',
  CHAT: 'chat',
  SHARE: 'share',
  MORE: 'more',
  STATS: 'statistics',
  VIEW_ON_OPENSOOQ: 'view_on_opensooq',
  APPLICANTS: 'applicants',
  PROMOTE_VIP: 'promote_vip',
  PROMOTE: 'promote',
  REPOST: 'repost',
  CARFAX: 'CarFax',
  CARSEER: 'CarSeer',
  MOJAZ: 'Mojaz',
  TERMS_OF_USE: 'terms_of_use',
}

interface ListingInterface {
  listingId: number
  status: number
  title: {
    text: string
    fontSize: string
    textColor: string
    style: {
      underline: boolean
      bold: boolean
    }
  }
  snippetTxt: string
  category: {
    text: string
    fontSize: string
    textColor: string
    style: {
      underline: boolean
      bold: boolean
    }
  }
  subCategory: {
    text: string
    fontSize: string
    textColor: string
    style: {
      underline: boolean
      bold: boolean
    }
  }
  listingImage: string
  mediaIcons: any[]
  listingImageCount: number
  price: {
    text: string
    fontSize: string
    textColor: string
    style: {
      underline: boolean
      bold: boolean
    }
  }
  location: {
    text: string
    fontSize: string
    textColor: string
    style: {
      underline: boolean
      bold: boolean
    }
  }
  features: {
    isPromoted: boolean
    isBumpup: boolean
    isVip: boolean
  }
  isDeleted: boolean
  isExpired: boolean
  isRejected: boolean
  isPendingPayment: boolean
  isPendingReview: boolean
  created: {
    text: string
    fontSize: string
    textColor: string
    style: {
      underline: boolean
      bold: boolean
    }
  }
  expires: {
    text: string
    fontSize: string
    textColor: string
    style: {
      underline: boolean
      bold: boolean
    }
  }
  quality: {
    text: string
    fontSize: string
    textColor: string
    style: {
      underline: boolean
      bold: boolean
    }
    isEnabled: boolean
  }
  strs: string
  leads: {
    totalViews: {
      text: number
      fontSize: string
      textColor: string
      style: {
        underline: boolean
        bold: boolean
      }
    }
    totalLeads: {
      text: number
      fontSize: string
      textColor: string
      style: {
        underline: boolean
        bold: boolean
      }
    }
  }
  addons: {
    whatsapp: boolean
    sms: boolean
    chat: boolean
    no_similar: boolean
    branding: boolean
  }
  flags: any[]
  listingStatus: {
    text: string
    fontSize: string
    textColor: string
    backgroundColor: string
    style: {
      underline: boolean
      bold: boolean
    }
  }
  applicantsStatus?: {
    text: string
    fontSize: string
    textColor: string
    actionType: string
    backgroundColor: string
    style: {
      underline: boolean
      bold: boolean
    }
  }
  rejectionReason: {
    icon: string
    text: string
    fontSize: string
    textColor: string
    backgroundColor: string
    style: {
      underline: boolean
      bold: boolean
    }
  }
  postActions: {
    text: string
    fontSize: string
    textColor: string
    style: {
      underline: boolean
      bold: boolean
    }
    icon: string
    actionType: string
    items?: {
      text: string
      fontSize: string
      textColor: string
      style: {
        underline: boolean
        bold: boolean
      }
      icon: string
      actionType: string
    }[]
  }[]
  loggingData: ListingLoggingData
}

export interface ListingInterfaceDesktop extends ListingInterface {
  leadsActions: {
    text: string
    fontSize: string
    textColor: string
    icon: string
    style: {
      underline: boolean
      bold: boolean
    }
    actionType: string
    isActive: boolean
  }[]
}

export interface ListingInterfaceMobile extends ListingInterface {
  leadsActions: {
    text: string
    fontSize: string
    textColor: string
    backgroundColor: string

    style: {
      underline: boolean
      bold: boolean
    }
    actionType: string
    isActive: boolean
  }
}

export type PostActionInput = {
  action: string
  listing
  isActive?: boolean
  options?: any[]
}

interface ListingLoggingData {
  id: number
  memberId?: number
  country?: string
  neighborhoodName?: string
  city?: {name?: string}
  category?: {label: string}
  subCategory?: string
  service: string
  listingStatus?: string
}
